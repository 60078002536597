import React from "react"
import IntroductionStyles from "./introduction.module.scss"
import bill from "../../../images/bill_small.png"

const IntroductionSplash = () => (
  <>
    <div className={IntroductionStyles.splash}>
      <img
        src={bill}
        alt="A cartoon of Bill"
        className={IntroductionStyles.bill}
      />
      <div className={IntroductionStyles.aligner}>
        <h1>Hello!</h1>
        <h2>
          I'm Bill, a software
          <br />
          engineer living in
          <br />
          <span className={IntroductionStyles.myHome}>
            <span className={IntroductionStyles.green}>Du</span>bl
            <span className={IntroductionStyles.gold}>in</span>
          </span>
          .
        </h2>
      </div>
    </div>
  </>
)

export default IntroductionSplash
